import * as React from "react"
import Seo from "../components/Seo"
import GbLogo from "../components/GbLogo"
import { Typography, Box, Grid, useMediaQuery, Theme } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Anime from "react-anime"
import Typewriter from "typewriter-effect"
import { FloatingGb } from "../components/FloatingGb"

const IndexPage = () => {
  const isSmScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  )
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  )

  return (
    <>
      <Seo title="Home" />
      <Box
        display="flex"
        alignItems="center"
        height="100%"
        width="100%"
        marginTop="40px"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography variant="h1">Nan's Marketplace</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography variant="h4">Coming soon</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default IndexPage
